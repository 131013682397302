
import { Component, Prop, Watch } from "vue-property-decorator";
import { ExtVue } from "../lib";
import { fabric } from "fabric";
import { SegmentationImage } from "../lib/image";

@Component({
  components: {},
})
export default class SegmentationPanel extends ExtVue {
  private name = "SegmentationPanel";

  @Prop() value!: any;
  @Prop() annotation!: any;
  @Prop({default: 500}) height!: number;


  private canvas!: fabric.Canvas;
  private baseImage!: fabric.Image;
  private segImage!: fabric.Image;
  private initialZoom = 0;
  private initialized = false;
  private segObject!: SegmentationImage;

  private circleCursor!: fabric.Circle;
  private squareCursor!: fabric.Rect;
  
  labels: any[] = [];

  opacity = 255;
  cursorShape = "square";
  cursorSize = 10;
  paintOver: any = null;
  private strokeWidth = 20;
  private cursorDown = false;
  private removeMode = false;
  currentLabel: any = null;

  @Watch("height")
  onHeightChanged() {
    this.resize();
  }

  @Watch("opacity")
  onOpacityChanged() {
    this.drawSegmentation();
  }

  @Watch("cursorSize")
  onCursorSizeChanged(v: number) {
    this.circleCursor.set({radius: v / 2});
    this.squareCursor.set({width: v, height: v});
  }

  @Watch("value")
  onValueChanged() {
    if (this.annotation) this.renderData();
  }

  @Watch("annotation")
  onAnnotationChanged() {
    if (this.value) this.renderData()
  }

  get cursorShapes() {
    return [
      {text: "Square", value: "square"},
      {text: "Circle", value: "circle"},
    ]
  }

  undoChanges() {
    if (this.segObject) {
      this.segObject.undo();
      this.drawSegmentation();
    }
  }

  async clearSegmentation() {
    if (this.segObject) {
      this.$showProgress();
      this.segObject.saveChanges();
      this.segObject.clearData();
      this.$emit('changed', this.segObject.getData());
      await this.drawSegmentation();
      this.$hideProgress();
    }
  }

  get segmentationLabels() {
    const items = this.annotation?.segmentationLabels || [];
    return items;
  }

  get currentLabelText() {
    return this.currentLabel ? this.currentLabel.name : ''
  }

  async setInitialData() {
    if (!this.annotation || !this.value) return;
    
    const colorMap: any = {};
    for (let i = 0; i < this.segmentationLabels.length; i++) {
      const lab = this.segmentationLabels[i];
      colorMap[lab.value] = lab.color;
    }

    const annot = (this.value.annotations || []).filter((a: any) => a.annotationId.toString() === this.annotation._id.toString())[0];
    if (annot) {
      const data = annot.segmentation || "";
      this.segObject = new SegmentationImage({width: this.baseImage.width!, height: this.baseImage.height!, colorMap});
      if (data && data !== "") await this.segObject.setData(data);
    } else {
      this.segObject = new SegmentationImage({width: this.baseImage.width!, height: this.baseImage.height!, colorMap});
    }
  }

  onLabelClicked(item: any) {
    this.currentLabel = item;
    this.squareCursor.set({stroke: this.currentLabel?.color || 'black'});
    this.circleCursor.set({stroke: this.currentLabel?.color || 'black'});
  }

  async drawImage() {
    const data = this.value?.data;
    this.baseImage = await this.makeImage(data);
    this.baseImage.set({selectable: false});
    this.canvas.setBackgroundImage(this.baseImage, () => {})
    this.canvas.centerObject(this.baseImage);
    this.zoomToScreen();
  }

  async drawSegmentation() {
    if (!this.segObject) {
      await this.setInitialData();
      return;
    };

    let op = Math.floor(Math.max(0, Math.min(this.opacity, 255)));
    const data = this.segObject.makePng(op);

    if (this.segImage) {
      this.segImage.setSrc(data , () => {
        this.canvas.renderAll();
      });
      this.canvas.bringToFront(this.segImage);
      this.canvas.bringToFront(this.circleCursor);
      this.canvas.bringToFront(this.squareCursor);
    } else {
      this.segImage = await this.makeImage(data);
      this.segImage.set({selectable: false, left: this.baseImage.left!, top: this.baseImage.top!, originX: 'left', originY: 'top'});
      this.canvas.add(this.segImage);
      this.canvas.bringToFront(this.segImage);
      this.canvas.bringToFront(this.circleCursor);
      this.canvas.bringToFront(this.squareCursor);
      this.canvas.renderAll();
    }
  }

  performZoom(x: any, y: any, zoom: any) {
    const mc = this.baseImage.getBoundingRect(false, true);
    const cz = this.canvas.getZoom();
    const compZoom = Math.max(zoom, this.initialZoom);

    if (compZoom === this.initialZoom) {
      if (compZoom === cz) return;
      this.zoomToScreen();
    } else {
      const ratio = 0.2 / this.canvas.getZoom();
      this.circleCursor.set({strokeWidth: this.strokeWidth * ratio});
      this.squareCursor.set({strokeWidth: this.strokeWidth * ratio});
      this.canvas?.zoomToPoint({x: mc.left + (x * cz), y: mc.top + (y * cz)}, compZoom);
    }
  }

  zoomToScreen() {  
    this.canvas.setViewportTransform([1,0,0,1,0,0]);

    const cw = this.canvas.width!;
    const ch = this.canvas.height!
    const iw = this.baseImage.width!;
    const ih = this.baseImage.height!;
    const zoom = Math.min(((cw - 10)/iw), ((ch - 10)/ih));

    this.canvas.zoomToPoint({x: cw/2, y: ch/2}, zoom);
    this.initialZoom = zoom;
  }

  initialize() {
    const can = this.$refs.canvas;
    const width = can.offsetWidth;

    this.canvas = new fabric.Canvas(can, {
      fireRightClick: true,
      stopContextMenu: true,
      selection: false,
      hoverCursor: "pointer"
    });

    const ratio = 0.2 / this.canvas.getZoom();

    this.circleCursor = new fabric.Circle({
      radius: this.cursorSize / 2,
      left: 0,
      top: 0,
      originX: 'center',
      originY: 'center',
      strokeWidth: this.strokeWidth * ratio,
      fill: 'rgba(0,0,0,0)',
      stroke: this.currentLabel?.color || 'black',
      selectable: false,
      visible: false
    })

    this.squareCursor = new fabric.Rect({
      width: this.cursorSize,
      height: this.cursorSize,
      left: 0,
      top: 0,
      originX: 'center',
      originY: 'center',
      strokeWidth: this.strokeWidth * ratio,
      fill: 'rgba(0,0,0,0)',
      stroke: this.currentLabel?.color || 'black',
      selectable: false,
      visible: false
    })

    this.canvas.setDimensions({width: width, height: this.height});
    this.canvas.add(this.circleCursor);
    this.canvas.add(this.squareCursor);

    this.canvas.bringToFront(this.circleCursor);
    this.canvas.bringToFront(this.squareCursor);

    this.setEvents();
    this.initialized = true;
  }

  setEvents() {
    this.canvas.on('mouse:wheel', async (opt: any) => {
      const delta = opt.e.deltaY;
      let zoom = this.canvas.getZoom();
      zoom -= delta / 500;
      if (zoom > 50) zoom = 50;
      if (zoom < 0.1) zoom = 0.1;

      let x = opt.absolutePointer.x - this.baseImage!.left!
      let y = opt.absolutePointer.y - this.baseImage!.top!;

      if (x >= 0 && x <= this.baseImage!.width! && y >= 0 && y <= this.baseImage!.height!) {
        this.performZoom(x, y, zoom);
      }

      opt.e.preventDefault();
      opt.e.stopPropagation();
    });

    this.canvas.on('mouse:down', (opt: any) => {
      this.removeMode = opt.button === 3;
      if (!this.segObject) return;
      let x = Math.floor(opt.absolutePointer.x - this.baseImage!.left!)
      let y = Math.floor(opt.absolutePointer.y - this.baseImage!.top!);
      if (x < 0 || x > this.baseImage.width! || y < 0 || y > this.baseImage.height!) return;
      if (!this.currentLabel) return;
      this.segObject.saveChanges();
      this.segObject.setLabel({point: {x, y}, value: this.removeMode ? 0 : this.currentLabel.value, shape: this.cursorShape, size: this.cursorSize, paintOver: this.removeMode ? null: this.paintOver});
      
      this.cursorDown = true;
      opt.e.preventDefault();
      opt.e.stopPropagation();
    })

    this.canvas.on('mouse:out', () => {
      this.hideCursor()
    });

    this.canvas.on('mouse:move', (opt: any) => {
      this.showCursor(opt.absolutePointer.x, opt.absolutePointer.y);
      if (!this.cursorDown) return;
      if (!this.segObject) return;
      let x = Math.floor(opt.absolutePointer.x - this.baseImage!.left!)
      let y = Math.floor(opt.absolutePointer.y - this.baseImage!.top!);
      if (x < 0 || x > this.baseImage.width! || y < 0 || y > this.baseImage.height!) return;
      if (!this.currentLabel) return;
      this.segObject.setLabel({point: {x, y}, value: this.removeMode ? 0 : this.currentLabel.value, shape: this.cursorShape, size: this.cursorSize, paintOver: this.removeMode ? null: this.paintOver});
      opt.e.preventDefault();
      opt.e.stopPropagation();

    });

    this.canvas.on('mouse:up', () => {
      if (this.cursorDown && this.segObject) {
        this.drawSegmentation();
        this.$emit('changed', this.segObject.getData())
      }
      this.cursorDown = false;
    });
  }

  showCursor(x: any, y: any) {
    this.circleCursor.set({left: x, top: y, visible: this.cursorShape === 'circle'});
    this.squareCursor.set({left: x, top: y, visible: this.cursorShape === 'square'});
    this.canvas.renderAll();
  }

  hideCursor() {
    this.circleCursor.set({visible: false});
    this.squareCursor.set({visible: false});
    this.canvas.renderAll();
  }

  async renderData() {
    this.$showProgress();

    if (!this.initialized) {
      this.initialize()
    }

    if (this.value && this.annotation) {
      this.canvas.clear();
      this.labels = [];
      await this.drawImage();

      await this.setInitialData();
      await this.drawSegmentation();
    }

    this.$hideProgress();
    
  }

  resize() {
    this.canvas.setHeight(this.height);
    this.renderData();
  }

  async makeImage(data: any): Promise<fabric.Image> {
    return new Promise((resolve: any) => {
      fabric.Image.fromURL(data, (img) => {
        resolve(img);
      })
    })
  }

  mounted() {
    this.initialize();
    this.renderData();
  }
}
