import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

interface ProgressBarInfo {
  value: number | undefined | null;
  text: any;
  show: any;
}

interface ConfirmInfo {
  timeout: number;
  dialog: boolean;
  text: any;
  yesString: string;
  noString: string;
  onYes: any;
  onNo: any;
  params: any;
}

export default new Vuex.Store({
  state: {
    snackbar: {
      success: {
        timeout: 2000,
        dialog: false,
        text: ""
      },
      error: {
        timeout: 5000,
        dialog: false,
        text: ""
      },
      confirm: ({
        timeout: -1,
        dialog: false,
        text: "",
        yesString: "Yes",
        noString: "No",
        onYes: null,
        onNo: null,
        params: null,
      } as ConfirmInfo)
    },
    progressbar: ({
      value: 0,
      text: "",
      show: false
    } as ProgressBarInfo),
    user: {},
    eventChannel: (null as any),
    workspace: {},
    workspaceAccess: {}
  },
  getters: {},
  mutations: {
    setUser(state, data) {
      state.user = data;
    },
    setWorkspace(state, data) {
      sessionStorage.setItem('workspaceId', data._id);
      state.workspace = data;
    }
  },
  actions: {},
  modules: {},
});
