
import { Component } from "vue-property-decorator";
import { ExtVue } from "../lib"

@Component({})
export default class Login extends ExtVue {
  private name = "Login";
  mode = "login";
  data = {
    email: "",
    password: "",
    strategy: "local"
  }

  signupData = {
    name: "",
    email: "",
    password: "",
    repeatPassword: ""
  }

  async login() {
    this.$showProgress();
    try {
      const res = await this.$app.authenticate(this.data);
      this.$store.commit('setUser', res.user);
      const redirect = this.$route.query?.redirect || '/';
      this.$router.replace(redirect).catch(() => {});
    } catch (error) {
      this.$showErrorMsg((error as any).message);
    }
    this.$hideProgress();
  }

  async signup() {
    this.$showProgress();
    try {
      await this.$app.service('users').create(this.signupData);
      this.mode = "login";
      this.$showSuccessMsg('Account successfully created. login below to access your area!');
    } catch (error) {
      this.$showErrorMsg((error as any).message);
    }
    this.$hideProgress();
  }
}
