import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import Workspace from "../views/Workspaces.vue";
import Users from "../views/Users.vue";
import Collections from "../views/Collections.vue";
import Annotations from "../views/Annotations.vue";
import Images from "../views/Images.vue";
import Worker from "../views/Worker.vue";

import { app } from '@/services';
import store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "workspaces",
        name: "workspaces",
        component: Workspace,
      },
      {
        path: "users",
        name: "users",
        component: Users,
      },
      {
        path: "collections",
        name: "collections",
        component: Collections,
      },
      {
        path: "config",
        name: "config",
        component: Annotations,
      },
      {
        path: "images",
        name: "images",
        component: Images,
      },
      {
        path: "annotate/:annotationId/:collectionIds",
        name: "annotate",
        component: Worker,
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const freePaths: string[] = ['/login'];

function checkAccess(access: any): boolean {
  return true;
}

async function beforeEach(to: any, from: any, next: any) {
  
  if (freePaths.includes(to.path)) {
    next();
  } else {
    
    if (!app.authentication.authenticated) {
      try {
        const res = await app.reAuthenticate();
        store.commit('setUser', res.user);
      } catch (error) {
        next({ path: '/login', query: { redirect: to.path } });
        return;
      }
    }
    
    if (!to.meta) {
      next();
    } else {
      if (!checkAccess(to.meta.access)) {
        next({ path: '/login' });
        return;
      }
      next();
    }
  }
}

router.beforeEach(beforeEach);

export default router;
