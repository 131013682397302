import io from "socket.io-client";
import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import authentication from "@feathersjs/authentication-client";
import findOne from "./find-one";
import count from "./count";

const APIURL = process.env.VUE_APP_API_URL || "http://api.annotator.com";

const socket = io(APIURL, {
  transports: ["websocket"],
  timeout: 60 * 1000,
  forceNew: true,
});

const client = feathers();
client.configure(socketio(socket));
client.configure(authentication({ storage: sessionStorage }));
client.configure(findOne());
client.configure(count());

export const app = client;
