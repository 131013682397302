
import { Component } from "vue-property-decorator";
import { ExtVue } from "./lib";

@Component({ name: "App" })
export default class App extends ExtVue {

  onYes() {
    this.$store.state.snackbar.confirm.dialog = false;
    if (this.$store.state.snackbar.confirm.onYes) {
      this.$store.state.snackbar.confirm.onYes(this.$store.state.snackbar.confirm.params);
    }
  }

  onNo() {
    this.$store.state.snackbar.confirm.dialog = false;
    if (this.$store.state.snackbar.confirm.onNo) {
      this.$store.state.snackbar.confirm.onNo(this.$store.state.snackbar.confirm.params);
    }
  }

  get indeterminate() {
    const value = this.$store.state.progressbar.value;
    return !(value || value === 0);
  }

  get value() {
    const value = this.$store.state.progressbar.value;
    return value;
  }

  get html() {
    const value = this.$store.state.progressbar.text;
    return value;
  }

  mounted() {
    this.$store.state.eventChannel = this.$channel;
  }

}
