
import { Component, Watch } from "vue-property-decorator";
import { ExtVue } from "../lib"

@Component({})
export default class ImageView extends ExtVue {
  private name = "ImageView";
  objectName = "Image"
  private service = "images";
  loading = false;
  search = "";
  selected = [];
  collections: any[] = []
  filterCollections: any[] = [];
  currentFilterCollections: any[] = [];

  mode = "create";
  dialog = false;
  data: any = {};

  previewDialog = false;
  previewIndex = 0;
  previewItemId = null;
  previewData = null;
  previewCollections: any[] = [];
  previewItems: any[] = [];
  previewName = "";

  options: any = {
    itemsPerPage: 10
  }
  totalItems = 0

  private itemData: any[] = [];
  searchFields: any[] = ['name', 'description'];
  private currentSearch = "";

  headers: any[] = [
    {text: 'Name', value: 'name'},
    {text: 'Collections', value: 'collectionNames'},
    {text: 'Description', value: 'description'},
  ];

  flHeaders: any[] = [
    {text: 'Name', value: 'name'},
    {text: 'Filename', value: 'filename'},
    {text: 'Status', value: 'status'},
    {text: 'Size', value: 'size'},
    {text: 'Actions', value: 'actions'},
  ]

  get fileList() {
    const items: any = [];
    for (let i = 0; i < this.data.files.length; i++) {
      let currentCount: string = (Number(this.data.count || '1') + i).toString()
      if (this.data.precision > currentCount.length) {
        currentCount = currentCount.padStart(this.data.precision, '0');
      }

      items.push(
        {
          name: this.data.customNaming ? `${this.data.prefix}${currentCount}${this.data.suffix}` : this.data.files[i].name,
          filename: this.data.files[i].name,
          size: `${Number(this.data.files[i].size / 1000000).toPrecision(2)} MB`,
          status: this.data.status[i] ? (this.data.status[i].success ? 'success' : this.data.status[i].error) : ''
        }
      )
    }
    return items;
  }

  removeFile(index: any) {
    if (this.data.files.length > index && index >= 0) {
      this.data.files.splice(index, 1)
    }
  }

  get items() {
    const list = this.itemData || []
    const cols = this.collections;
    list.forEach((itm: any) => {
      itm.collectionNames = cols.filter((c: any) => (itm.collections || []).includes(c._id)).map((c: any) => c.name).join(", ")
    })
    return list;
  }

  @Watch("options", {deep: true})
  onOptionsChanged() {
    this.load();
  }

  runSearch() {
    this.currentSearch = this.search;
    this.currentFilterCollections = ([] as any[]).concat(this.filterCollections);
    this.load();
  }

  async load() {
    if (!this.$workspace._id) return;
    this.loading = true;
    try {
      const query: any = {
        workspaceId: this.$workspace._id,
        $select: ['name', 'collections', 'description']
      };

      if (this.options.itemsPerPage > 0) {
        query.$limit = this.options.itemsPerPage;
        query.$skip = (this.options.itemsPerPage * (this.options.page - 1));
      } else {
        query.$paginate = false;
      }
      
      if (this.currentFilterCollections.length > 0) {
        query.collections = {$in: this.currentFilterCollections}
      }

      if (this.currentSearch.trim().length > 0 && this.searchFields.length > 0) {
        query.$or = this.searchFields.map((s: any) => {
          const sparam: any = {};
          sparam[s] = {$regex: this.currentSearch, $options: 'i'};
          return sparam;
        });
      }
      let items = await this.$app.service(this.service).find({query});
      if (!items.data) {
        items = {
          data: items,
          total: items.length,
          skip: 0,
          limit: 0
        }
      }
      this.itemData = items.data || [];
      this.totalItems = items.total;
    } catch (error) {
      this.$showErrorMsg((error as any).message);
    }
    this.loading = false;
  }

  refresh() {
    this.data = {
      workspaceId: this.$workspace._id,
      isMultiple: false,
      customNaming: false,
      prefix: "",
      suffix: "",
      precision: "",
      count: "",
      data: "",
      collections: [],
      tags: [],
      name: "",
      files: [],
      description: "",
      status: []
    }
  }

  async selectMultipleFiles() {
    const items = await this.$selectFile('image/*', true);
    for (let i = 0; i < items.length; i++) {
      this.data.files.push(items[i]);
    }
  }

  async selectSingleFiles() {
    const items = await this.$selectFile('image/*', false);
    this.data.files = [];
    for (let i = 0; i < items.length; i++) {
      this.data.files.push(items[i]);
    }
  }

  add() {
    this.refresh();
    this.mode = "create";
    this.dialog = true;
  }

  async editItem(item: any) {
    try {
      const itm: any = await this.$app.service(this.service).get(item._id, {query: {$select: ['name', 'collections', 'description']}});
      this.refresh();
      this.mode = "edit";
      this.data = Object.assign({}, this.data, itm);
      this.dialog = true;
    } catch (error) {
      this.$showErrorMsg((error as any).message);
    }
  }

  async deleteItem() {
    if (await this.$confirm("Remove selected Item(s) ?")) {
      let errorCount = 0;
      
      for (let i = 0; i < this.selected.length; i++) {
        const item: any = this.selected[i];
        try {
          await this.$app.service(this.service).remove(item._id);
        } catch (error) {
          errorCount += 1;
        }
      }

      this.selected = [];
      this.load();
      this.$showSuccessMsg(`Removed images with ${errorCount} errors!`);
    }
  }

  async save() {
    const isValid = this.$refs.mainForm.validate();
    if (isValid) {
      if (await this.$confirm('Save Data ?')) {
        if (this.mode === 'edit') {
          const data = {
            name: this.data.name,
            collections: this.data.collections,
            description: this.data.description
          }

          try {
            await this.$app.service(this.service).patch(this.data._id, data);
            this.$showSuccessMsg('Image information successfully updated!');
            this.dialog = false;
            this.previewName = data.name;
            this.previewCollections = data.collections || [];
            this.load();
          } catch (error) {
            this.$showErrorMsg((error as any).message);
          }
          return;
        }
        if (this.data.isMultiple) {
          this.$showProgress();
          const total = this.data.files.length;
          let errorCount = 0;
          for (let i = 0; i < total; i++) {
            const file = this.data.files[i];
            const data: any = {
              workspaceId: this.$workspace._id,
              name: this.fileList[i].name,
              collections: this.data.collections,
              description: this.data.description,
              data: await this.$getBase64URL(file)
            }

            try {
              await this.$app.service(this.service).create(data);
              if (this.data.status[i]) {
                this.data.status[i] = {success: true}
              } else {
                this.data.status.push({success: true})
              }
            } catch (error) {
              errorCount += 1;
              if (this.data.status[i]) {
                this.data.status[i] = {error: (error as any).message}
              } else {
                this.data.status.push({error: (error as any).message})
              }
            }
            this.$setProgress(Number(Number((i+1) * 100/total).toFixed(2)))
          }
          this.$hideProgress();
          this.$showSuccessMsg(`Files uploaded with ${errorCount} errors!`);
          if (errorCount === 0) this.refresh();
        } else {
          const file = this.data.files[0];
          const data: any = {
            workspaceId: this.$workspace._id,
            name: this.data.name || file.name,
            collections: this.data.collections,
            description: this.data.description,
            data: await this.$getBase64URL(file)
          }

          try {
            await this.$app.service(this.service).create(data);
            this.$showSuccessMsg(`File successfully uploaded!`);
            this.refresh();
          } catch (error) {
            this.$showErrorMsg((error as any).message);
          }
        }

      }
    }
  }

  async loadCollections() {
    try {
      const query: any = {
        workspaceId: this.$workspace._id,
        $paginate: false,
        $select: ['name']
      };
      this.collections = await this.$app.service('collections').find({query});
    } catch (error) {
      this.$showErrorMsg((error as any).message);
    }
  }

  async preview() {
    this.previewItems = [].concat(this.selected);
    this.previewIndex = 0;
    await this.loadPreview();
    this.previewDialog = true;
  }

  previewNext() {
    if (this.previewIndex + 1 < this.previewItems.length) {
      this.previewIndex += 1;
      this.loadPreview()
    }
  }

  previewPrev() {
    if (this.previewIndex > 0) {
      this.previewIndex -= 1;
      this.loadPreview();
    }
  }

  async loadPreview() {
    const item: any = this.previewItems[this.previewIndex];
    try {
      const img: any = await this.$app.service(this.service).get(item._id, {query: {$select: ['data', 'name', 'collections']}});
      this.previewData = img.data;
      this.previewName = img.name;
      this.previewCollections = img.collections;
      this.previewItemId = img._id;
    } catch (error) {
      this.$showErrorMsg((error as any).message);
    }
  }

  async deletePreview() {
    if (await this.$confirm('Delete current image ?')) {
      if (this.previewItems.length === 1) {
        this.previewDialog = false;
      }

      this.previewItems.splice(this.previewIndex, 1);

      try {
        await this.$app.service(this.service).remove(this.previewItemId);
        this.selected = [];
        this.load();
      } catch (error) {
        this.$showErrorMsg((error as any).message);
      }


      if (this.previewIndex === this.previewItems.length - 1) {
        this.previewIndex -= 1;
      }

      this.loadPreview();

    }
  }

  get previewCollectionNames() {
    const cols = this.collections;
    return cols.filter((c: any) => (this.previewCollections || []).includes(c._id)).map((c: any) => c.name).join(", ")
  }

  mounted() {
    setTimeout(() => {
      this.loadCollections();
      this.load();
    }, 1000)
  }
}
