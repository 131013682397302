
import { Component } from "vue-property-decorator";
import { ExtVue } from "../lib"

@Component({})
export default class Home extends ExtVue {
  private name = "Home";
  workspaces: any[] = [];
  annotations: any[] = [];
  collections: any[] = [];

  switchWorkspaceInfo = {
    dialog: false,
    workspaceId: null
  }

  get showingWorker() {
    return this.$route.path.includes('annotate')
  }

  workerInfo: any = {
    dialog: false,
    data: {
      annotationId: null,
      collectionIds: []
    }
  }

  async logout() {
    await this.$app.logout();
    this.$router.replace('/').catch(() => {});
    location.reload();
  }

  async loadWorkspaces() {
    try {
      this.workspaces = await this.$app.service('me').get('workspaces');
    } catch (error) {
      this.$showErrorMsg((error as any).message);
    }
  }

  async switchWorkspace() {
    const workspaceId = this.switchWorkspaceInfo.workspaceId;
    if (await this.loadWorkspace(workspaceId)) {
      this.$showSuccessMsg(`Workspace successfully switched to ${this.$workspace.name}`);
      this.switchWorkspaceInfo.dialog = false;
    };
  }

  async loadWorkspace(workspaceId: any) {
    try {
      const workspace: any = await this.$app.service('workspaces').get(workspaceId);
      workspace.isAdmin = workspace.adminId.toString() === this.$store.state.user?._id.toString();
      this.$store.commit('setWorkspace', workspace);
      return true;
    } catch (error) {
      this.$showErrorMsg((error as any).message);
      return false;
    }
  }

  async openSwitch() {
    await this.loadWorkspaces();
    this.switchWorkspaceInfo.workspaceId = null;
    this.switchWorkspaceInfo.dialog = true;
  }

  async loadExistingWorkspace() {
    await this.loadWorkspaces();
    if (this.workspaces.length == 1) {
      await this.loadWorkspace(this.workspaces[0]._id);
    } else if (this.workspaces.length > 1) {
      const workspaceId: any = sessionStorage.getItem('workspaceId');
      const wk: any = this.workspaces.filter((w: any) => w._id.toString() === workspaceId.toString())[0];
      if (wk) {
        await this.loadWorkspace(wk._id);
      }
    }
  }

  async openWorker() {
    this.workerInfo.data.annotationId = null;
    this.workerInfo.data.collectionIds = [];
    await this.loadAnnotations();
    await this.loadCollections();
    this.workerInfo.dialog = true;
  }

  async loadCollections() {
    try {
      this.collections = await this.$app.service('me').get('collections', {query: {workspaceId: this.$workspace._id}});
    } catch (error) {
      this.$showErrorMsg((error as any).message);
    }
  }

  async loadAnnotations() {
    try {
      this.annotations = await this.$app.service('me').get('annotations', {query: {workspaceId: this.$workspace._id}});
    } catch (error) {
      this.$showErrorMsg((error as any).message);
    }
  }

  startAnnotation() {
    this.$router.replace({
      name: 'annotate',
      params: {
        annotationId: this.workerInfo.data.annotationId,
        collectionIds: this.workerInfo.data.collectionIds.join("_")
      }
    }).catch(() => {});
    this.workerInfo.dialog = false;
  }

  mounted() {
    this.loadExistingWorkspace();
  }
}
