
import { Component, Prop, Watch } from "vue-property-decorator";
import { ExtVue } from "../lib";
import { fabric } from "fabric";

@Component({
  components: {},
})
export default class ClassifcationPanel extends ExtVue {
  private name = "ClassifcationPanel";

  @Prop() value!: any;
  @Prop() annotation!: any;
  @Prop({default: 500}) height!: number;


  private canvas!: fabric.Canvas;
  private baseImage!: fabric.Image;
  private overlayText!: fabric.Text;
  private initialZoom = 0;
  private initialized = false;
  
  labels: any[] = [];

  private strokeWidth = 20;

  get supportMulti() {
    return this.annotation?.supportMultiClass || false;
  }

  @Watch("height")
  onHeightChanged() {
    this.resize();
  }

  @Watch("value")
  onValueChanged() {
    if (this.annotation) this.renderData();
  }

  @Watch("annotation")
  onAnnotationChanged() {
    if (this.value) this.renderData()
  }

  get classificationLabels() {
    const items = this.annotation?.classificationLabels || [];
    return items;
  }

  get currentLabelText() {
    const labs = this.classificationLabels.filter((c: any) => this.labels.includes(c._id))
    const names = labs.map((c: any) => c.name).join(", ");
    return names;
  }

  setInitialData() {
    if (!this.annotation || !this.value) return;
    const annot = (this.value.annotations || []).filter((a: any) => a.annotationId.toString() === this.annotation._id.toString())[0];
    if (annot) {
      this.labels = annot.classification || [];
    }
    this.setLabelText();
  }

  onLabelClicked(item: any) {
    if (!this.supportMulti) {
      if (this.labels.length === 0 || this.labels[0] !== item._id) {
        this.labels = [item._id];
        this.$emit('changed', this.labels);
      }
    } else {
      const existing = this.labels.filter((l) => l.toString() === item._id.toString())[0];
      if (existing) {
        const index = this.labels.indexOf(existing);
        this.labels.splice(index, 1)
      } else {
        this.labels.push(item._id);
      }
      this.$emit('changed', this.labels);
    }
    this.setLabelText();
  }

  setLabelText() {
    const labs = this.classificationLabels.filter((c: any) => this.labels.includes(c._id))
    const names = labs.map((c: any) => c.name).join(", ");
    this.overlayText.text = names;
    this.overlayText.set({
      textBackgroundColor: labs[0]?.color || "white",
      fill: labs[0]?.darkColor ? "white" : "black",
      padding: 50,
    });
    this.overlayText.visible = !this.supportMulti
    this.canvas.renderAll();
  }

  async drawImage() {
    const data = this.value?.data;
    this.baseImage = await this.makeImage(data);
    this.baseImage.set({selectable: false});
    this.canvas.setBackgroundImage(this.baseImage, () => {})
    this.canvas.centerObject(this.baseImage);
    this.zoomToScreen();
    this.overlayText.set({
      left: this.baseImage.left,
      top: this.baseImage.top
    });
    this.canvas.bringToFront(this.overlayText);
  }

  performZoom(x: any, y: any, zoom: any) {
    const mc = this.baseImage.getBoundingRect(false, true);
    const cz = this.canvas.getZoom();
    const compZoom = Math.max(zoom, this.initialZoom);

    if (compZoom === this.initialZoom) {
      if (compZoom === cz) return;
      this.zoomToScreen();
    } else {
      this.canvas?.zoomToPoint({x: mc.left + (x * cz), y: mc.top + (y * cz)}, compZoom);
    }
  }

  zoomToScreen() {  
    this.canvas.setViewportTransform([1,0,0,1,0,0]);

    const cw = this.canvas.width!;
    const ch = this.canvas.height!
    const iw = this.baseImage.width!;
    const ih = this.baseImage.height!;
    const zoom = Math.min(((cw - 10)/iw), ((ch - 10)/ih));

    this.canvas.zoomToPoint({x: cw/2, y: ch/2}, zoom);
    this.initialZoom = zoom;
  }

  initialize() {
    const can = this.$refs.canvas;
    const width = can.offsetWidth;

    this.canvas = new fabric.Canvas(can, {
      fireRightClick: true,
      stopContextMenu: true
    });

    this.canvas.setDimensions({width: width, height: this.height});
    
    this.overlayText = new fabric.Text('', {
      fontSize: 150,
      left: 0,
      top: 0,
      originX: 'left',
      originY: 'top',
      textBackgroundColor: 'red',
      selectable: false
    })

    this.canvas.add(this.overlayText);
    this.setEvents();
    this.initialized = true;
    this.canvas.bringToFront(this.overlayText);
  }

  setEvents() {
    this.canvas.on('mouse:wheel', async (opt: any) => {
      const delta = opt.e.deltaY;
      let zoom = this.canvas.getZoom();
      zoom -= delta / 500;

      if (zoom > 20) zoom = 20;
      if (zoom < 0.1) zoom = 0.1;

      let x = opt.absolutePointer.x - this.baseImage!.left!
      let y = opt.absolutePointer.y - this.baseImage!.top!;

      if (x >= 0 && x <= this.baseImage!.width! && y >= 0 && y <= this.baseImage!.height!) {
        this.performZoom(x, y, zoom);
      }

      opt.e.preventDefault();
      opt.e.stopPropagation();
    });
  }

  async renderData() {
    if (!this.initialized) {
      this.initialize()
    }

    if (this.value && this.annotation) {
      this.canvas.clear();
      this.labels = [];
      await this.drawImage();
    }

    this.setInitialData();
  }

  resize() {
    this.canvas.setHeight(this.height);
    this.renderData();
  }

  async makeImage(data: any): Promise<fabric.Image> {
    return new Promise((resolve: any) => {
      fabric.Image.fromURL(data, (img) => {
        resolve(img);
      })
    })
  }

  mounted() {
    this.initialize();
    this.renderData();
  }
}
