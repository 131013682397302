
import { Component } from "vue-property-decorator";
import DetectionPanel from "../components/DetectionPanel.vue";
import ClassifcationPanel from "../components/ClassificationPanel.vue";
import { ExtVue, sleep } from "../lib"
import SegmentationPanel from "../components/SegmentationPanel.vue";

@Component({
  components: {DetectionPanel, ClassifcationPanel, SegmentationPanel}
})
export default class Worker extends ExtVue {
  private name = "Worker";

  private annotationId: any = null;
  private collectionIds: any[] = [];
  private annotationData: any = [];
  private classificationData: any[] = [];
  private segmentationData: any = null;

  calHeight = 500;
  viewMode = ""

  loading = false;
  currentIndex = 0;
  totalItems = 0;
  gotoIndex = 1;
  
  autoSave = false;
  annotationChanged = false;
  classificationChanged = false;
  segmentationChanged = false;

  currentImage: any = {};
  currentAnnotation: any = {}

  get canSave() {
    return this.annotationChanged || this.classificationChanged || this.segmentationChanged;
  }

  get hasDetection() {
    return (this.currentAnnotation?.detectionLabels || []).length > 0;
  }

  get hasClassification() {
    return (this.currentAnnotation?.classificationLabels || []).length > 0;
  }

  get hasSegmentation() {
    return (this.currentAnnotation?.segmentationLabels || []).length > 0;
  }

  close() {
    this.$router.replace('/')
  }

  switchMode(mode: any) {
    this.viewMode = mode;
  }

  gotoItem() {
    if (this.gotoIndex > 0 && this.gotoIndex - 1 < this.totalItems) {
      this.currentIndex = this.gotoIndex - 1;
      this.loadImage();
    }
  }

  async onDetectionAnnotationChanged(data: any) {
    this.annotationData = data;
    this.annotationChanged = true;
    if (this.autoSave) {
      this.saveChanges();
    }
  }

  async onClassificationAnnotationChanged(data: any) {
    this.classificationData = data;
    this.classificationChanged = true;
    if (this.autoSave) {
      this.saveChanges();
    }
  }

  async onSegmentationAnnotationChanged(data: any) {
    this.segmentationData = data;
    this.segmentationChanged = true;
    if (this.autoSave) {
      this.saveChanges();
    }
  }

  async saveChanges() {
    if (!this.annotationChanged && !this.classificationChanged && !this.segmentationChanged) return;

    try {
      const imageId = this.currentImage._id;
      const image = await this.$app.service('images').get(imageId, {query: {$select: ['annotations']}});
      const annotation = (image.annotations || []).filter((a: any) => a.annotationId === this.annotationId)[0];

      if (annotation) {
        const updates: any = {};
        
        if (this.annotationChanged) {
          updates['annotations.$.detections'] = this.annotationData;
        }

        if (this.classificationChanged) {
          updates['annotations.$.classification'] = this.classificationData;
        }

        if (this.segmentationChanged) {
          updates['annotations.$.segmentation'] = this.segmentationData;
        }

        await this.$app.service("images").patch(imageId, {$set: updates}, {query: {'annotations._id': annotation._id}});
      } else {
        const updates: any = {annotationId: this.annotationId};
        
        if (this.annotationChanged) {
          updates.detections = this.annotationData;
        }

        if (this.classificationChanged) {
          updates.classification = this.classificationData;
        }

        if (this.segmentationChanged) {
          updates.segmentation = this.segmentationData;
        }

        await this.$app.service("images").patch(imageId, {$push: {annotations: updates}});
      }

      this.annotationChanged = false;
      this.classificationChanged = false;
      this.segmentationChanged = false;
      this.annotationData = [];
      this.classificationData = [];
      this.segmentationData = null;

    } catch (error) {
      this.$showErrorMsg((error as any).message);
    }
  }

  async previousImage() {
    if (this.currentIndex > 0) {
      this.currentIndex -= 1;
      this.loadImage();
    }
  }

  async nextImage() {
    if (this.currentIndex + 1 < this.totalItems) {
      this.currentIndex += 1;
      this.loadImage();
    }
  }

  async loadAnnotation() {
    if (!this.annotationId) return;

    try {
      this.currentAnnotation = await this.$app.service('annotations').get(this.annotationId);
      if (this.hasClassification) {
        this.viewMode = 'classification';
      } else if (this.hasDetection) {
        this.viewMode = 'detection';
      } else if (this.hasSegmentation) {
        this.viewMode = 'segmentation';
      }
    } catch (error) {
      this.$showErrorMsg((error as any).message);
    }
  }

  async checkAccess() {
    if (!this.$workspace?._id) {
      await sleep(2000);
    }

    if (!this.$workspace?._id) {
      this.close();
      return false;
    }
    
    try {
      const userAnnots: any[] = await this.$app.service('me').get('annotations', {query: {workspaceId: this.$workspace._id}});
      const annotIds = userAnnots.map((a: any) => a._id.toString());
      if (!annotIds.includes(this.annotationId!.toString())) {
        this.close();
        return false;
      }

      const userColls: any[] = await this.$app.service('me').get('collections', {query: {workspaceId: this.$workspace._id}});
      const collIds = userColls.map((a: any) => a._id.toString());
      for (let i = 0; i < this.collectionIds.length; i++) {
        if (!collIds.includes(this.collectionIds[i]!.toString())) {
          this.close();
          return false;
        }
      }

    } catch (error) {
      this.$showErrorMsg((error as any).message); 
      return false;
    }

    return true;
  }

  async loadImage() {
    if (this.annotationChanged) {
      if (await this.$confirm('You have unsaved work, Do you want to save ?')) {
        await this.saveChanges();
      }
    }

    try {
      const query: any = {
        $limit: 1,
        $skip: this.currentIndex,
        collections: {$in: this.collectionIds}
      };
      const res = await this.$app.service('images').find({query});
      this.totalItems = res.total;
      if (res.data && res.data.length > 0) {
        this.currentImage = res.data[0];
      }
      this.gotoIndex = this.currentIndex + 1;
      this.annotationChanged = false;
      this.annotationData = [];
    } catch (error) {
      this.$showErrorMsg((error as any).message);
    }
  }

  resizeItems() {
    this.calHeight = document.documentElement.clientHeight - 60;
  }

  beforeDestroy() {
    this.removeListeners();
  }

  attachListeners() {
    window.addEventListener('resize', this.resizeItems);
  }

  removeListeners() {
    window.removeEventListener("resize", this.resizeItems);
  }

  mounted() {
    if (this.$route.params?.annotationId) this.annotationId = this.$route.params?.annotationId;
    if (this.$route.params?.collectionIds) this.collectionIds = this.$route.params?.collectionIds.split('_');
    this.resizeItems();
    this.attachListeners();

    this.$showProgress();
    this.checkAccess().then((res: any) => {
      if(res) {
        this.loadAnnotation();
        this.loadImage();
      }
      this.$hideProgress();
    });

  }
}
