
import { Component } from "vue-property-decorator";
import { ExtVue } from "../lib"

@Component({})
export default class Configuration extends ExtVue {
  private name = "Configuration";
  objectName = "Configuration"
  private service = "annotations";
  loading = false;
  search = "";

  mode = "create";
  dialog = false;
  data: any = {};

  private itemData: any[] = [];
  searchFields: any[] = ['name', 'description'];

  annotationTypes: any[] = [
    {text: 'Classification', value: 'classification'},
    {text: 'Detection', value: 'detection'},
    {text: 'Segmentation', value: 'segmentation'},
  ];

  detectionShapes: any[] = [
    {text: 'Rectangle', value: 'rectangle'},
    {text: 'Square', value: 'square'},
    {text: 'Circle', value: 'circle'},
    {text: 'Ellipse', value: 'ellipse'},
  ];

  headers: any[] = [
    {text: 'Name', value: 'name'},
    {text: 'Types', value: 'annotationType'},
    {text: 'Description', value: 'description'},
    {text: 'Actions', value: 'actions'}
  ];

  cHeaders: any[] = [
    {text: "Name", value: "name"},
    {text: "Color", value: "color"},
    {text: "Value", value: "value"},
    {text: "Actions", value: "actions"},
  ];
  dHeaders: any[] = [
    {text: "Name", value: "name"},
    {text: "Color", value: "color"},
    {text: "Shape", value: "shape"},
    {text: "Value", value: "value"},
    {text: "Actions", value: "actions"},
  ];
  sHeaders: any[] = [
    {text: "Name", value: "name"},
    {text: "Color", value: "color"},
    {text: "Value", value: "value"},
    {text: "Actions", value: "actions"},
  ];

  cData: any = {
    dialog: false,
    mode: "create",
    index: null,
    data: {}
  }

  sData: any = {
    dialog: false,
    mode: "create",
    index: null,
    data: {}
  }

  dData: any = {
    dialog: false,
    mode: "create",
    index: null,
    data: {}
  }

  get items() {
    return this.itemData;
  }

  async load() {
    if (!this.$workspace._id) return;
    this.loading = true;
    try {
      const query: any = {$paginate: false, workspaceId: this.$workspace._id};
      if (this.search.trim().length > 0 && this.searchFields.length > 0) {
        query.$or = this.searchFields.map((s: any) => {
          const sparam: any = {};
          sparam[s] = {$regex: this.search, $options: 'i'};
          return sparam;
        });
      }
      const items = await this.$app.service(this.service).find({query});
      this.itemData = items;
    } catch (error) {
      this.$showErrorMsg((error as any).message);
    }
    this.loading = false;
  }

  refresh() {
    this.data = {
      workspaceId: this.$workspace._id,
      name: "",
      annotationType: [],
      supportMultiClass: false,
      classificationLabels: [],
      detectionLabels: [],
      segmentationLabels: [],
      description: "",
    }
  }

  add() {
    this.refresh();
    this.mode = "create";
    this.dialog = true;
  }

  async editItem(item: any) {
    try {
      const itm: any = await this.$app.service(this.service).get(item._id);
      this.refresh();
      this.mode = "edit";
      this.data = Object.assign({}, this.data, itm);
      this.dialog = true;
    } catch (error) {
      this.$showErrorMsg((error as any).message);
    }
  }

  async deleteItem(item: any) {
    if (await this.$confirm("Remove selected Item ?")) {
      try {
        await this.$app.service(this.service).remove(item._id);
        this.$showSuccessMsg(`${this.objectName} successfully removed!`);
        this.load();
      } catch (error) {
        this.$showErrorMsg((error as any).message);
      }
    }
  }

  async save() {
    const isValid = this.$refs.mainForm.validate();
    if (isValid) {
      if (await this.$confirm('Save Data ?')) {
        try {
          if (this.mode == "edit") {
            await this.$app.service(this.service).patch(this.data._id, this.data);
          } else {
            await this.$app.service(this.service).create(this.data);
          }
          this.load();
          this.refresh();
          this.$showSuccessMsg(`${this.objectName} successfully ${this.mode == 'create' ? 'created!': 'updated!'}`);
          if (this.mode == "edit") this.dialog = false;
        } catch (error) {
          this.$showErrorMsg((error as any).message);
        }
      }
    }
  }

  resetClassificationLabel() {
    this.cData.data = {
      name: "",
      value: null,
      color: ""
    };
  }

  addClassificationLabel() {
    this.resetClassificationLabel();
    this.cData.mode = "create";
    this.cData.dialog = true;
  }

  editClassificationLabel(item: any, index: any) {
    this.resetClassificationLabel();
    this.cData.data = Object.assign({}, this.cData.data, item)
    this.cData.mode = "edit";
    this.cData.index = index;
    this.cData.dialog = true;
  }

  async deleteClassificationLabel(index: any) {
    if (await this.$confirm('Remove selected label?')) {
      this.data.classificationLabels.splice(index, 1);
    }
  }

  saveClassificationLabel() {
    const isValid = this.$refs.cdataForm.validate();
    if (isValid) {
      if (this.cData.mode === "create") {
        const data = Object.assign({}, this.cData.data);
        this.data.classificationLabels.push(data);
        this.resetClassificationLabel()
        this.$showSuccessMsg('Label successfully added!');
      } else {
        const index = this.cData.index;
        if (index || index === 0) {
          const data = Object.assign({}, this.cData.data);
          this.data.classificationLabels.splice(index, 1, data);
          this.$showSuccessMsg('Label successfully updated!');
          this.cData.dialog = false;
        }
      }
    }
  }


  resetDetectionLabel() {
    this.dData.data = {
      name: "",
      value: null,
      shape: "rectangle",
      fixedSize: false,
      size: {},
      color: "",
      darkColor: false
    };
  }

  addDetectionLabel() {
    this.resetDetectionLabel();
    this.dData.mode = "create";
    this.dData.dialog = true;
  }

  editDetectionLabel(item: any, index: any) {
    this.resetDetectionLabel();
    this.dData.data = Object.assign({}, this.dData.data, item)
    this.dData.mode = "edit";
    this.dData.index = index;
    this.dData.dialog = true;
  }

  async deleteDetectionLabel(index: any) {
    if (await this.$confirm('Remove selected label?')) {
      this.data.detectionLabels.splice(index, 1);
    }
  }

  saveDetectionLabel() {
    const isValid = this.$refs.ddataForm.validate();
    if (isValid) {
      if (this.dData.mode === "create") {
        const data = Object.assign({}, this.dData.data);
        this.data.detectionLabels.push(data);
        this.resetDetectionLabel();
        this.$showSuccessMsg('Label successfully added!');
      } else {
        const index = this.dData.index;
        if (index || index === 0) {
          const data = Object.assign({}, this.dData.data);
          this.data.detectionLabels.splice(index, 1, data);
          this.$showSuccessMsg('Label successfully updated!');
          this.dData.dialog = false;
        }
      }
    }
  }


  resetSegmentationLabel() {
    this.sData.data = {
      name: "",
      value: null,
      color: ""
    };
  }

  addSegmentationLabel() {
    this.resetSegmentationLabel();
    this.sData.mode = "create";
    this.sData.dialog = true;
  }

  editSegmentationLabel(item: any, index: any) {
    this.resetSegmentationLabel();
    this.sData.data = Object.assign({}, this.sData.data, item)
    this.sData.mode = "edit";
    this.sData.index = index;
    this.sData.dialog = true;
  }

  async deleteSegmentationLabel(index: any) {
    if (await this.$confirm('Remove selected label?')) {
      this.data.segmentationLabels.splice(index, 1);
    }
  }

  saveSegmentationLabel() {
    const isValid = this.$refs.sdataForm.validate();
    if (isValid) {
      if (this.sData.mode === "create") {
        const data = Object.assign({}, this.sData.data);
        this.data.segmentationLabels.push(data);
        this.resetSegmentationLabel();
        this.$showSuccessMsg('Label successfully added!');
      } else {
        const index = this.sData.index;
        if (index || index === 0) {
          const data = Object.assign({}, this.sData.data);
          this.data.segmentationLabels.splice(index, 1, data);
          this.$showSuccessMsg('Label successfully updated!');
          this.sData.dialog = false;
        }
      }
    }
  }

  mounted() {
    setTimeout(() => {
      this.load();
    }, 1000);
  }
}
