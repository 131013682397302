
import { Component, Prop, Watch } from "vue-property-decorator";
import { ExtVue } from "../lib";
import { fabric } from "fabric";

@Component({
  components: {},
})
export default class DetectionPanel extends ExtVue {
  private name = "DetectionPanel";

  @Prop() value!: any;
  @Prop() annotation!: any;
  @Prop({default: 500}) height!: number;


  private canvas!: fabric.Canvas;
  private baseImage!: fabric.Image;
  private initialZoom = 0;
  private initialized = false;
  private mouseDown = false;

  currentLabel: any = null;
  shapeSelected = false;

  private shapes: fabric.Object[] = [];
  
  private labels: any[] = [];

  private pt1: any = {};
  private pt2: any = {};
  private strokeWidth = 20;

  @Watch("height")
  onHeightChanged() {
    this.resize();
  }

  @Watch("value")
  onValueChanged() {
    if (this.annotation) this.renderData();
  }

  @Watch("annotation")
  onAnnotationChanged() {
    if (this.value) this.renderData()
  }

  get annotationLabels() {
    const items = this.annotation?.detectionLabels || [];
    return items;
  }

  switchLabel(item: any) {
    this.currentLabel = item;
  }

  async drawImage() {
    const data = this.value?.data;
    this.baseImage = await this.makeImage(data);
    this.baseImage.set({selectable: false});
    this.canvas.setBackgroundImage(this.baseImage, () => {})
    this.canvas.centerObject(this.baseImage);
    this.zoomToScreen();
  }

  drawExistingLabels() {
    if (!this.annotation || !this.value) return;

    const annot = (this.value.annotations || []).filter((a: any) => a.annotationId.toString() === this.annotation._id.toString())[0];
    if (annot) {
      const labels: any[] = annot.detections || [];
      for (let i = 0; i < labels.length; i++) {
        const label = labels[i];
        if ((label.points || []).length >= 2 && label.label) {
          const labelInfo = this.annotationLabels.filter((a: any) => a._id.toString() === label.label.toString())[0];
          if (labelInfo) {
            const size = this.processActualSize(label.points[0], label.points[1], labelInfo);
            if (size) {
              const ssize = this.actualToScreen(size.x, size.y, size.width, size.height);
              const shape = this.drawShape(ssize.x, ssize.y, ssize.width, ssize.height, labelInfo);
              if (shape) {
                label.points = [
                  {x: size.x, y: size.y},
                  {x: size.x + size.width, y: size.y + size.height}
                ]
                this.labels.push(label);
                this.shapes.push(shape);
              }
            }
          }
        }
      }
    }
  }

  processActualSize(pt1: any, pt2: any, labelInfo: any) {
    let ax = Math.min(pt1.x, pt2.x);
    let ay = Math.min(pt1.y, pt2.y);

    let actualWidth = Math.abs(pt1.x - pt2.x);
    let actualHeight = Math.abs(pt1.y - pt2.y);

    if (labelInfo.shape === 'rectangle') {
      if (labelInfo.fixedSize && labelInfo.size?.width && labelInfo.size?.height) {
        actualHeight = labelInfo.size.height;
        actualWidth = labelInfo.size.width;
      }
    }

    if (labelInfo.shape === 'circle') {
      if (labelInfo.fixedSize && labelInfo.size?.raduis) {
        actualHeight = labelInfo.size.raduis * 2;
        actualWidth = labelInfo.size.raduis * 2;
      }

    }

    if (labelInfo.shape === 'square') {
      if (labelInfo.fixedSize && labelInfo.size?.length) {
        actualHeight = labelInfo.size.length;
        actualWidth = labelInfo.size.length;
      }
    }

    if (labelInfo.shape === 'ellipse') {
      if (labelInfo.fixedSize && labelInfo.size?.raduis2 && labelInfo.size?.raduis1) {
        actualHeight = labelInfo.size.raduis2 * 2;
        actualWidth = labelInfo.size.raduis1 * 2;
      }
    }

    if (ax < 0) ax = 0;
    if (ay < 0) ay = 0;

    actualWidth = Math.min(actualWidth, this.baseImage.width!);
    actualHeight = Math.min(actualHeight, this.baseImage.height!);

    if (ax + actualWidth >= this.baseImage.width!) {
      if (this.baseImage.width! >= actualWidth) {
        ax = this.baseImage.width! - actualWidth - 1;
      } else {
        return;
      }
    }

    if (ay + actualHeight >= this.baseImage.height!) {
      if (this.baseImage.height! >= actualHeight) {
        ay = this.baseImage.height! - actualHeight - 1;
      } else {
        return;
      }
    }

    ax = Math.floor(ax);
    ay = Math.floor(ay)
    actualWidth = Math.floor(actualWidth);
    actualHeight = Math.floor(actualHeight);

    return {x: ax, y: ay, width: actualWidth, height: actualHeight};
  }

  actualToScreen(ax : any, ay: any, actualWidth: any, actualHeight: any) {
    const ratio = 1;

    return {
      x: (ax + this.baseImage.left!) * ratio,
      y: (ay + this.baseImage.top!) * ratio,
      width: actualWidth * ratio,
      height: actualHeight * ratio,
    }

  }

  addShape(pt1: any, pt2: any, labelInfo: any, selected?: any) {
    if (pt1 && pt2) {
      let x = Math.min(pt1.x, pt2.x);
      let y = Math.min(pt1.y, pt2.y);

      let width = Math.abs(pt1.x - pt2.x);
      let height = Math.abs(pt1.y - pt2.y);

      const xratio = 1;
      const yratio = 1;

      let actualWidth = width * xratio;
      let actualHeight = height * yratio;

      let ax = (x - this.baseImage.left!) * xratio//;
      let ay = (y - this.baseImage.top!) * yratio//;

      if ((width === 0 || height === 0)) return;

      if (labelInfo.shape === 'rectangle') {
        if (labelInfo.fixedSize && labelInfo.size?.width && labelInfo.size?.height) {
          actualHeight = labelInfo.size.height;
          actualWidth = labelInfo.size.width;
        }
      }

      if (labelInfo.shape === 'circle') {
        if (labelInfo.fixedSize && labelInfo.size?.raduis) {
          actualHeight = labelInfo.size.raduis * 2;
          actualWidth = labelInfo.size.raduis * 2;
        }

      }

      if (labelInfo.shape === 'square') {
        if (labelInfo.fixedSize && labelInfo.size?.length) {
          actualHeight = labelInfo.size.length;
          actualWidth = labelInfo.size.length;
        }
      }

      if (labelInfo.shape === 'ellipse') {
        if (labelInfo.fixedSize && labelInfo.size?.raduis2 && labelInfo.size?.raduis1) {
          actualHeight = labelInfo.size.raduis2 * 2;
          actualWidth = labelInfo.size.raduis1 * 2;
        }
      }

      if (ax < 0) ax = 0;
      if (ay < 0) ay = 0;

      actualWidth = Math.min(actualWidth, this.baseImage.width!);
      actualHeight = Math.min(actualHeight, this.baseImage.height!);

      if (ax + actualWidth > this.baseImage.width!) {
        if (this.baseImage.width! >= actualWidth) {
          ax = this.baseImage.width! - actualWidth;
        } else {
          return;
        }
      }

      if (ay + actualHeight > this.baseImage.height!) {
        if (this.baseImage.height! >= actualHeight) {
          ay = this.baseImage.height! - actualHeight;
        } else {
          return;
        }
      }

      ax = Math.floor(ax);
      ay = Math.floor(ay)
      actualWidth = Math.floor(actualWidth);
      actualHeight = Math.floor(actualHeight);

      const sz = this.actualToScreen(ax, ay, actualWidth, actualHeight);
      const shape = this.drawShape(sz.x, sz.y, sz.width, sz.height, labelInfo);

      if (shape) {
        this.labels.push({
          points: [
            {x: ax, y: ay},
            {x: ax + actualWidth, y: ay + actualHeight}
          ],
          label: labelInfo._id
        })

        this.shapes.push(shape);
        if (selected) {
          this.canvas.setActiveObject(shape);
        }
        this.$emit("changed", this.labels);
      }

    }
  }

  drawShape(x: any, y: any, width: any, height: any, labelInfo: any) {
    if ((width === 0 || height === 0) && labelInfo.fixedSize) return;
      const ratio = 0.2 / this.canvas.getZoom();

      let shape!: fabric.Object; 

      if (labelInfo.shape === 'rectangle') {
        shape = new fabric.Rect({
          left: x,
          top: y,
          originX: 'left',
          originY: 'top',
          width,
          height,
          strokeWidth: this.strokeWidth * ratio,
          fill: 'rgba(0,0,0,0)',
          stroke: labelInfo.color,
          selectable: false
        });
        
        shape.setControlsVisibility({
          mtr: false
        });
      }

      if (labelInfo.shape === 'circle') {

        const radius = Math.min(width, height) / 2;

        shape = new fabric.Circle({
          left: x,
          top: y,
          originX: 'left',
          originY: 'top',
          radius,
          lockUniScaling: true,
          strokeWidth: this.strokeWidth * ratio,
          fill: 'rgba(0,0,0,0)',
          stroke: labelInfo.color,
          selectable: false
        });

        shape.setControlsVisibility({
          mb: false,
          ml: false,
          mr: false,
          mt: false,
          mtr: false
        });
      }

      if (labelInfo.shape === 'square') {
        const size = Math.min(width, height);

        shape = new fabric.Rect({
          left: x,
          top: y,
          originX: 'left',
          originY: 'top',
          width: size,
          height: size,
          lockUniScaling: true,
          strokeWidth: this.strokeWidth * ratio,
          fill: 'rgba(0,0,0,0)',
          stroke: labelInfo.color,
          selectable: false
        });

        shape.setControlsVisibility({
          mb: false,
          ml: false,
          mr: false,
          mt: false,
          mtr: false
        });
      }

      if (labelInfo.shape === 'ellipse') {
        shape = new fabric.Ellipse({
          left: x,
          top: y,
          originX: 'left',
          originY: 'top',
          rx: width / 2,
          ry: height / 2,
          strokeWidth: this.strokeWidth * ratio,
          fill: 'rgba(0,0,0,0)',
          stroke: labelInfo.color,
          selectable: false
        });
        shape.setControlsVisibility({
          mtr: false
        });
      }

      if (shape) {
        
        if (labelInfo.fixedSize) {
          shape.hasControls = false;
        }
        shape.bringToFront();
        this.canvas.add(shape);
        this.canvas.renderAll();
      }

      return shape;
  }

  deleteSelection() {
    const sel = this.canvas.getActiveObject();
    if (sel) {
      this.canvas.remove(sel);
      const ind = this.shapes.indexOf(sel);
      this.shapes.splice(ind, 1);
      this.labels.splice(ind, 1);
      this.$emit("changed", this.labels);
    }
  }

  async deleteAll() {
    if (await this.$confirm('Clear all labels?')) {
      for (let i = 0; i < this.shapes.length; i++) {
        const obj = this.shapes[i];
        this.canvas.remove(obj);
      }

      this.shapes = [];
      this.labels = [];
      this.$emit("changed", this.labels);
    }
  }

  moveSelectionFront() {
    const sel = this.canvas.getActiveObject();
    if (sel) this.canvas.bringToFront(sel);
  }

  moveSelectionBack() {
    const sel = this.canvas.getActiveObject();
    if (sel) {
      this.canvas.sendToBack(sel);
    }
  }

  onShapeSelected() {
    this.shapeSelected = true;
  }

  selectShape(x: any, y: any) {
    let curSel: any = null;
    let zindex = -1;
    const pt = new fabric.Point(x, y);
    for (let i = 0; i < this.shapes.length; i++) {
      const sel = this.shapes[i];
      // check collusion;
      if (sel.containsPoint(pt, null, true)) {
        const curzindex = this.canvas.getObjects().indexOf(sel);
        if (curzindex > zindex) {
          curSel = sel;
          zindex = curzindex;
        }
      }
    }

    if (curSel) {
      this.shapeSelected = true;
      this.canvas.setActiveObject(curSel);
      this.canvas.renderAll();
    }
  }

  performZoom(x: any, y: any, zoom: any) {
    const mc = this.baseImage.getBoundingRect(false, true);
    const cz = this.canvas.getZoom();
    const compZoom = Math.max(zoom, this.initialZoom);

    if (compZoom === this.initialZoom) {
      if (compZoom === cz) return;
      this.zoomToScreen();
      this.resizeStrokes();
    } else {
      this.canvas?.zoomToPoint({x: mc.left + (x * cz), y: mc.top + (y * cz)}, compZoom);
      this.resizeStrokes();
    }
  }

  resizeStrokes() {
    const ratio = 0.2 / this.canvas.getZoom();
    for (let i = 0; i < this.shapes.length; i++) {
      this.shapes[i].set({strokeWidth: this.strokeWidth * ratio});
    }
  }

  zoomToScreen() {  
    this.canvas.setViewportTransform([1,0,0,1,0,0]);

    const cw = this.canvas.width!;
    const ch = this.canvas.height!
    const iw = this.baseImage.width!;
    const ih = this.baseImage.height!;
    const zoom = Math.min(((cw - 10)/iw), ((ch - 10)/ih));

    this.canvas.zoomToPoint({x: cw/2, y: ch/2}, zoom);
    this.initialZoom = zoom;
  }

  initialize() {
    const can = this.$refs.canvas;
    const width = can.offsetWidth;
    this.canvas = new fabric.Canvas(can, {
      fireRightClick: true,
      stopContextMenu: true
    });
    this.canvas.setDimensions({width: width, height: this.height});
    this.setEvents();
    this.initialized = true;
  }

  setEvents() {
    this.canvas.on('mouse:wheel', async (opt: any) => {
      const delta = opt.e.deltaY;
      let zoom = this.canvas.getZoom();
      zoom -= delta / 500;

      if (zoom > 20) zoom = 20;
      if (zoom < 0.1) zoom = 0.1;

      let x = opt.absolutePointer.x - this.baseImage!.left!
      let y = opt.absolutePointer.y - this.baseImage!.top!;

      if (x >= 0 && x <= this.baseImage!.width! && y >= 0 && y <= this.baseImage!.height!) {
        this.performZoom(x, y, zoom);
      }

      opt.e.preventDefault();
      opt.e.stopPropagation();
    });

    this.canvas.on('mouse:down', (opt: any) => {

      if (opt.button === 3) {
        this.deleteSelection();
      }

      if (opt.button !== 1) return;

      if (this.canvas.getActiveObject()) return;

      if (this.mouseDown) return;

      const x = opt.absolutePointer.x - this.baseImage!.left!
      const y = opt.absolutePointer.y - this.baseImage!.top!;

      if (x >= 0 && x <= this.baseImage!.width! && y >= 0 && y <= this.baseImage!.height! && this.currentLabel) {
        this.mouseDown = true;

        this.pt1 = {x: x + this.baseImage!.left!, y: y + this.baseImage!.top!};
        this.pt2 = null;

        opt.e.preventDefault();
        opt.e.stopPropagation();
      }
    });

    this.canvas.on('mouse:up', (opt: any) => {
      if (!this.mouseDown) return;
      this.mouseDown = false;

      const x = opt.absolutePointer.x - this.baseImage!.left!
      const y = opt.absolutePointer.y - this.baseImage!.top!;

      if (x >= 0 && x <= this.baseImage!.width! && y >= 0 && y <= this.baseImage!.height! && this.currentLabel) {

        this.pt2 = {x: x + this.baseImage!.left!, y: y + this.baseImage!.top!};
        
        this.addShape(this.pt1, this.pt2, this.currentLabel);

        opt.e.preventDefault();
        opt.e.stopPropagation();
      }

    });

    this.canvas.on('mouse:dblclick', (opt: any) => {
      const x = opt.absolutePointer.x;
      const y = opt.absolutePointer.y;
      this.selectShape(x, y);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    })

    this.canvas.on('selection:cleared', (opt) => {
      this.shapeSelected = false;
    })

    this.canvas.on('object:modified', (opt) => {
      const obj = opt.target!;
      const index = this.shapes.indexOf(obj);
      const label = this.annotationLabels.filter((l: any) => l._id.toString() === this.labels[index].label.toString())[0];

      if (obj.scaleX === 1 && obj.scaleY === 1) {
        let x = obj.left!;
        let y = obj.top!;
        let w = obj.width!;
        let h = obj.height!;

        const xratio = 1;
        const yratio = 1;

        let aw = w * xratio;
        let ah = h * yratio;

        let ax = (x - this.baseImage.left!) * xratio//;
        let ay = (y - this.baseImage.top!) * yratio//;

        if (ax < 0) ax = 0;
        if (ay < 0) ay = 0;

        if (ax + aw > this.baseImage.width!) ax = this.baseImage.width! - aw - 1;
        if (ay + ah > this.baseImage.height!) ay = this.baseImage.height! - ah - 1;

        ax = Math.floor(ax);
        ay = Math.floor(ay)
        aw = Math.floor(aw);
        ah = Math.floor(ah);

        this.labels[index].points = [
          {x: ax, y: ay},
          {x: ax + aw, y: ay + ah}
        ];

        const sz = this.actualToScreen(ax, ay, aw, ah);

        if (sz.x !== x || sz.y !== y) {
          obj.set({left: sz.x, top: sz.y});
          this.canvas.renderAll();
        }

        this.$emit("changed", this.labels);
      } else {
        
        let x = obj.left!;
        let y = obj.top!;
        let w = obj.getScaledWidth();
        let h = obj.getScaledHeight();

        this.labels.splice(index, 1)
        this.shapes.splice(index, 1)
        this.canvas.remove(obj);
        this.addShape({x, y}, {x: x + w, y: y + h}, label, true);
      }
    });
  }

  async renderData() {
    if (!this.initialized) {
      this.initialize()
    }

    if (this.value && this.annotation) {
      this.canvas.clear();
      this.shapes = [];
      this.labels = [];
      await this.drawImage();
      this.drawExistingLabels();
    }
  }

  resize() {
    this.canvas.setHeight(this.height);
    this.renderData();
  }

  async makeImage(data: any): Promise<fabric.Image> {
    return new Promise((resolve: any) => {
      fabric.Image.fromURL(data, (img) => {
        resolve(img);
      })
    })
  }

  mounted() {
    this.initialize();
    this.renderData();
  }
}
