/* eslint @typescript-eslint/no-var-requires: 'off' */
import { Vue } from 'vue-property-decorator';
import store from '../store';
import { app } from '../services';
import nestedProperty from 'nested-property';

const globals: any = {};

const APIURL = process.env.VUE_APP_API_URL || "http://api.bonescreen.com";

export interface Type<T> extends Function { new (...args: any[]): T; }


export function sleep(time: number) {
  return new Promise((resolve: any) => {
    if (time > 0) {
      setTimeout(() => {
        resolve();
      }, time);
    } else {
      resolve();
    }
  });
}

export function fmAmt (value: any, decimals?: number, thouSep?: string, decimalSep?: string) {
  const v: any = value?.$numberDecimal ? Number(value?.$numberDecimal) : value;
  if (Number.isNaN(Number(v))) {
    return v;
  }
  const n = decimals || 2
  const s = thouSep || ','
  const c = decimalSep || '.'
  const re = '\\d(?=(\\d{' + 3 + '})+' + (n > 0 ? '\\D' : '$') + ')'
  const num = Number(v).toFixed(Math.max(0, ~~n));
  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
}

export function $amt(value: any) {
  return Number(value?.$numberDecimal || value || 0);
}

export function $showProgress(value?: number) {
  $setProgress(value);
  store.state.progressbar.show = true;
}

export function $hideProgress() {
  store.state.progressbar.show = false;
}

export function $setProgress(value?: number) {
  store.state.progressbar.value = value || 0;
}

export function $setProgressText(value: any) {
  store.state.progressbar.text = value;
}

export function $showSuccessMsg (message: string) {
  store.state.snackbar.success.text = message;
  store.state.snackbar.success.dialog = true;
}

export async function loadUDFs(objectType: any) {
  const schoolId = window.sessionStorage.getItem("schoolId")
  try {
    const query: any = {
      $paginate: false,
      objectTypes: objectType,
      $sort: {sort: 1}
    }
    if (schoolId) {
      query.$or = [{schoolId: null}, {schoolId}]
    } else {
      query.schoolId = null;
    }
    const items: any = await app.service("udfs").find({query});
    return items
  } catch (error) {
    return []
  }
}

export function $showErrorMsg (message: string) {
  store.state.snackbar.error.text = message;
  store.state.snackbar.error.dialog = true;
}

export async function $confirm(message: string, params?: any) {
  return new Promise((resolve: any) => {
    store.state.snackbar.confirm.onYes = () => {
      resolve(true);
    };

    store.state.snackbar.confirm.onNo = () => {
      resolve(false);
    };

    store.state.snackbar.confirm.params = params;
    store.state.snackbar.confirm.text = message;
    store.state.snackbar.confirm.dialog = true;
  })
}

export function setGlobal(key: string, data: any) {
  nestedProperty.set(globals, key, data);
}

export function getGlobal(key: string) {
  return nestedProperty.get(globals, key);
}

export const Validators = {
  isRequired() {
    return (va: any) => {
      if (Array.isArray(va)) {
        return va.length > 0 || 'Field is required!';
      }
      if (va || va === 0) {
        return true;
      } else {
        return 'Field is required!';
      }
    }
  },
  range(mi: any, ma: any, converter?: any) {
    return (va: any) => {
      let vc: any[] = [];

      if (Array.isArray(va)) {
        vc = [...va];
      } else {
        vc = [va];
      }

      for (let i = 0; i < vc.length; i++) {
        if (!((vc[i] || vc[i] === 0) && ((converter && converter(vc[i]) <= converter(ma)) || Number(vc[i]) <= Number(ma)))) {
          return `Value cannot exceed ${ma}`;
        }
        if (!((vc[i] || vc[i] === 0) && ((converter && converter(vc[i]) >= converter(mi)) || Number(vc[i]) >= Number(mi)))) {
          return `Value cannot be below ${mi}`;
        }
      }
      return true;
    }
  },
  max(m: any, converter?: any) {
    return (va: any) => {
      let vc: any[] = [];
      
      if (Array.isArray(va)) {
        vc = [...va];
      } else {
        vc = [va];
      }

      for (let i = 0; i < vc.length; i++) {
        if (!((vc[i] || vc[i] === 0) && ((converter && converter(vc[i]) <= converter(m)) || Number(vc[i]) <= Number(m)))) {
          return `Value cannot exceed ${m}`;
        }
      }
      return true;
    }
  },
  min(m: number, converter?: any) {
    return (va: any) => {
      let vc: any[] = [];
      
      if (Array.isArray(va)) {
        vc = [...va];
      } else {
        vc = [va];
      }

      for (let i = 0; i < vc.length; i++) {
        if (!((vc[i] || vc[i] === 0) && ((converter && converter(vc[i]) >= converter(m)) || Number(vc[i]) >= Number(m)))) {
          return `Value cannot be below ${m}`;
        }
      }
      return true;
    }
  },
  gt(m: number, converter?: any) {
    return (va: any) => {
      let vc: any[] = [];
      
      if (Array.isArray(va)) {
        vc = [...va];
      } else {
        vc = [va];
      }

      for (let i = 0; i < vc.length; i++) {
        if (!((vc[i] || vc[i] === 0) && ((converter && converter(vc[i]) > converter(m)) || Number(vc[i]) > Number(m)))) {
          return `Value should be greater than ${m}`;
        }
      }
      return true;
    }
  },
  lt(m: number, converter?: any) {
    return (va: any) => {
      let vc: any[] = [];
      
      if (Array.isArray(va)) {
        vc = [...va];
      } else {
        vc = [va];
      }

      for (let i = 0; i < vc.length; i++) {
        if (!((vc[i] || vc[i] === 0) && ((converter && converter(vc[i]) < converter(m)) || Number(vc[i]) < Number(m)))) {
          return `Value should be less than ${m}`;
        }
      }
      return true;
    }
  },
  gte(m: number, converter?: any) {
    return (va: any) => {
      let vc: any[] = [];
      
      if (Array.isArray(va)) {
        vc = [...va];
      } else {
        vc = [va];
      }

      for (let i = 0; i < vc.length; i++) {
        if (!((vc[i] || vc[i] === 0) && ((converter && converter(vc[i]) >= converter(m)) || Number(vc[i]) >= Number(m)))) {
          return `Value should be greater than ${m}`;
        }
      }
      return true;
    }
  },
  lte(m: number, converter?: any) {
    return (va: any) => {
      let vc: any[] = [];
      
      if (Array.isArray(va)) {
        vc = [...va];
      } else {
        vc = [va];
      }

      for (let i = 0; i < vc.length; i++) {
        if (!((vc[i] || vc[i] === 0) && ((converter && converter(vc[i]) <= converter(m)) || Number(vc[i]) <= Number(m)))) {
          return `Value should be less than ${m}`;
        }
      }
      return true;
    }
  },
  neq(m: number, converter?: any) {
    return (va: any) => {
      let vc: any[] = [];
      
      if (Array.isArray(va)) {
        vc = [...va];
      } else {
        vc = [va];
      }

      for (let i = 0; i < vc.length; i++) {
        if (!((vc[i] || vc[i] === 0) && ((converter && converter(vc[i]) !== converter(m)) || Number(vc[i]) !== Number(m)))) {
          return `Value cannot be equal to ${m}`;
        }
      }
      return true;
    }
  },
  eq(m: number, converter?: any) {
    return (va: any) => {
      let vc: any[] = [];
      
      if (Array.isArray(va)) {
        vc = [...va];
      } else {
        vc = [va];
      }

      for (let i = 0; i < vc.length; i++) {
        if (!((vc[i] || vc[i] === 0) && ((converter && converter(vc[i]) === converter(m)) || Number(vc[i]) === Number(m)))) {
          return `Value must be equal to ${m}`;
        }
      }
      return true;
    }
  },
  in(m: any[]) {
    return (va: any) => {
      let vc: any[] = []
      
      if (Array.isArray(va)) {
        vc = [...va];
      } else {
        vc = [va];
      }

      for (let i = 0; i < vc.length; i++) {
        if (!((vc[i] || vc[i] === 0) && m.includes(vc[i]))) {
          return `Value must be one of ${m}`;
        }
      }
      return true;
    }
  },
  nin(m: any[]) {
    return (va: any) => {
      let vc: any[] = []
      
      if (Array.isArray(va)) {
        vc = [...va];
      } else {
        vc = [va];
      }

      for (let i = 0; i < vc.length; i++) {
        if (!((vc[i] || vc[i] === 0) && !m.includes(vc[i]))) {
          return `Value must not be one of ${m}`;
        }
      }
      return true;
    }
  },
  includes(m: any) {
    return (va: any) => {
      let vc: any[] = []
      
      if (Array.isArray(va)) {
        vc = [...va];
      } else {
        vc = [va];
      }

      if (!vc.includes(m)) {
        return `Values must include ${m}`
      }
      return true;
    }
  },
  excludes(m: any) {
    return (va: any) => {
      let vc: any[] = [];
      
      if (Array.isArray(va)) {
        vc = [...va];
      } else {
        vc = [va];
      }

      if (vc.includes(m)) {
        return `Values must exclude ${m}`
      }
      return true;
    }
  },
  maxLen(m: number) {
    return (va: any) => {
      let vc: any[] = [];
      
      if (Array.isArray(va)) {
        vc = [...va];
      } else {
        vc = [va];
      }

      for (let i = 0; i < vc.length; i++) {
        if (vc[i] && vc[i].toString().length > m) {
          return `Maximum length should be ${m}`;
        }
      }
      return true;
    }
  },
  minLen(m: number) {
    return (va: any) => {
      let vc: any[] = [];
      
      if (Array.isArray(va)) {
        vc = [...va];
      } else {
        vc = [va];
      }

      for (let i = 0; i < vc.length; i++) {
        if (vc[i] && vc[i].toString().length < m) {
          return `Minimum length should be ${m}`;
        }
      }
      return true;
    }
  },
  regex(exp: string) {
    return (va: any) => {
      let vc: any[] = [];
      
      if (Array.isArray(va)) {
        vc = [...va];
      } else {
        vc = [va];
      }

      for (let i = 0; i < vc.length; i++) {
        const re = new RegExp(exp);
        const txt = vc[i] ? vc[i].toString() : ''

        if (!re.test(txt)) {
          return `Value fails regular expression test[${exp}]`;
        }
      }
      return true;
    }
  },
};

interface CustomEventHandler {
  isOnce: boolean;
  callback: EventListener;
}

export class EventEmitter {
  private _events: { [key: string] : CustomEventHandler[] } = {};

  on (name: string, listener: EventListener) {
    if (!this._events[name]) {
      this._events[name] = [];
    }

    this._events[name].push({isOnce: false, callback: listener});
  }

  once (name: string, listener: EventListener) {
    if (!this._events[name]) {
      this._events[name] = [];
    }
    this._events[name].push({isOnce: true, callback: listener});
  }

  removeListener (name: string, listenerToRemove?: EventListener) {
    if (!listenerToRemove && this._events[name]) {
      this._events[name] = [];
    } else if (this._events[name]) {
      const filterListeners = (listener: CustomEventHandler) => listener.callback !== listenerToRemove;
      this._events[name] = this._events[name].filter(filterListeners);
    }
  }

  clearListeners() {
    this._events = {};
  }

  emit (name: string, data?: any) {
    if (this._events[name]) {
      const calledCallbacks: any[] = [];
      for (let i = 0; i < this._events[name].length; i++) {
        const currentCallback: any = this._events[name][i].callback;
        if (!calledCallbacks.includes(currentCallback)) currentCallback(data);
        calledCallbacks.push(currentCallback);
      }

      const filterListeners = (listener: CustomEventHandler) => !listener.isOnce;
      this._events[name] = this._events[name].filter(filterListeners);
    }
  }
}

const eventChannel = new EventEmitter();

export class ExtVue extends Vue {
  $route!: any;
  $router!: any;
  $refs!: any;
  $emit!: any;
  $vuetify!: any;
  $store!: any;

  get $app() {
    return app;
  }

  get $channel() {
    return eventChannel;
  }

  $showProgress(value?: number) {
    this.$setProgress(value);
    this.$store.state.progressbar.show = true;
  }

  $hideProgress() {
    this.$store.state.progressbar.show = false;
  }

  $setProgress(value?: number) {
    this.$store.state.progressbar.value = value;
  }

  $setProgressText(value: any) {
    this.$store.state.progressbar.text = value;
  }

  $showSuccessMsg (message: string) {
    this.$store.state.snackbar.success.text = message;
    this.$store.state.snackbar.success.dialog = true;
  }
  
  $showErrorMsg (message: string) {
    this.$store.state.snackbar.error.text = message;
    this.$store.state.snackbar.error.dialog = true;
  }

  $confirm(message: string) {
    return new Promise((resolve: any) => {
      this.$store.state.snackbar.confirm.onYes = () => resolve(true);
      this.$store.state.snackbar.confirm.onNo = () => resolve(false);
      this.$store.state.snackbar.confirm.text = message;
      this.$store.state.snackbar.confirm.dialog = true;
    })
  }

  $fmAmt (value: number, decimals?: number, thouSep?: string, decimalSep?: string) {
    return fmAmt(value, decimals, thouSep, decimalSep);
  }

  $amt (value: any) {
    return $amt(value)
  }

  $precise(value: any): any {
    return Number.parseFloat(value).toFixed(4);
  }

  $parse(obj: any): any {
    if (obj) return JSON.parse(JSON.stringify(obj));
    return obj;
  }

  $getField(item: any, field: string) {
    return nestedProperty.get(item, field);
  }

  $setField(item: any, field: string, data: any) {
    return nestedProperty.set(item, field, data);
  }

  $hasField(item: any, field: string, options: any) {
    return nestedProperty.has(item, field, options);
  }
  
  $goto(url: any) {
    this.$router.replace(url)
    .catch(() => {});
  }

  dateConverter(d: any) {
    return new Date(d);
  }

  get $v() {
    return Validators;
  }

  get $isMobile() {
    const value = this.$vuetify.breakpoint.mobile;
    return value;
  }

  get $primaryColor() {
    return '#660708';
  }

  get $isSuper () {
    const isSuper = this.$store.state.user.isSuper || false;
    return isSuper;
  }

  get $isAdmin () {
    const isAdmin = this.$store.state.workspace.isAdmin || false;
    return isAdmin;
  }

  get $workspace() {
    return this.$store.state.workspace;
  }

  get $userAccess() {
    return (access: string[]) => {
      return this.$store.getters['checkUserAccess'](access)
    }
  }

  get $machineAccess() {
    return (access: string[]) => {
      return this.$store.getters['checkMachineAccess'](access)
    }
  }

  get $access() {
    return (access: any) => {
      const user = Array.isArray(access) ? access : access.user || [];
      const machine = Array.isArray(access) ? access : access.machine || [];
      return this.$userAccess(user) && this.$machineAccess(machine);
    }
  }

  $createAccess(object: any, action?: any) {
    const objects: any[] = Array.isArray(object) ? object : [object]
    const actions: any[] = !action ? ['display', 'create', 'edit'] : (Array.isArray(action) ? action : [action])
    let access: any[] = [];
    for (let o = 0; o < objects.length; o++) {
      for (let a = 0; a < actions.length; a++) {
        if (o === 0 && a === 0) access.push(`${objects[o]}:${actions[a]}`)
        else access = access.concat(['||', `${objects[o]}:${actions[a]}`])
      }
    }
    return access;
  }

  $selectFile(accept?: any, multiple = false): Promise<FileList> {
    return new Promise((resolve: any) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      if (accept) input.setAttribute('accept', accept);
      if (multiple) input.setAttribute('multiple', multiple.toString());
      input.onchange = (e: any) => {
        const files: any[] = e.target.files || e.dataTransfer.files;
        if (!files.length) {
          return;
        }
        resolve(files)
      };
      input.click();
    })
  }

  async $getBase64URL(file: any): Promise<string> {
    return await getBase64URL(file)
  }
}

export function createAccess(object: any, action?: any) {
  const objects: any[] = Array.isArray(object) ? object : [object]
  const actions: any[] = !action ? ['display', 'create', 'edit'] : (Array.isArray(action) ? action : [action])
  let access: any[] = [];
  for (let o = 0; o < objects.length; o++) {
    for (let a = 0; a < actions.length; a++) {
      if (o === 0 && a === 0) access.push(`${objects[o]}:${actions[a]}`)
      else access = access.concat(['||', `${objects[o]}:${actions[a]}`])
    }
  }
  return access;
}

export function makeAccess(objects: any, actions: any, isAnd?: boolean) {
  const op = isAnd ? '&&' : '||';
  const objs = Array.isArray(objects) ? objects : [objects];
  const acts = Array.isArray(actions) ? actions : [actions];
  const access = [];
  for (let o = 0; o < objs.length; o++) {
    for (let a = 0; a < acts.length; a++) {
      if (access.length > 0) access.push(op);
      access.push(`${objs[o]}:${acts[a]}`);
    }
  }
  return access;
}

export async function selectFile(accept?: any, multiple=false) {
  return new Promise((resolve : any, reject: any) =>{
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    if (accept) input.setAttribute('accept', accept);
    if (multiple) input.setAttribute('multiple', multiple.toString());
    input.onchange = (e: any) => {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        reject(new Error('No File Selected!'));
      } else {
        resolve(files);
      }
    };
    input.click();
  })
}

export async function selectExcelFile() {
  return await selectFile("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
}

export async function getBase64URL(file: any): Promise<string> {
  return new Promise((resolve: any, reject: any) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      resolve(e.target.result)
    }

    reader.onerror = (ev: ProgressEvent<FileReader>) => {
      reject('Unable to load data');
    }

    reader.readAsDataURL(file);
  })
}
