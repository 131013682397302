export interface SegmentationImageOptions {
  width: number;
  height: number;
  colorMap: any;
  data?: number[];
}

export interface SetLabelOptions {
  point: any;
  shape: string;
  size: number;
  value: number;
  paintOver?: number;
}


export class SegmentationImage {
  
  width: number = 0
  height: number = 0
  colorMap: any;
  private data: number[] = [];
  private history: any[] = [];
  private totalHist = 10;

  constructor(options: SegmentationImageOptions) {
    this.width = options.width;
    this.height = options.height;
    this.colorMap = options.colorMap;
    
    if (options.data) {
      this.data = options.data;
    } else {
      this.initialize();
    }
  }

  initialize() {
    this.data = new Array(this.width * this.height).join('0').split('').map(parseInt);
  }

  async setData(data: any) {
    if (typeof data !== 'string') return;
    return new Promise((resolve: any, reject: any) => {
      const img = new Image();
      
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d')!;
  
        ctx.canvas.width = img.width;
        ctx.canvas.height = img.height;
  
        ctx.drawImage(img, 0, 0, img.width, img.height);
  
        const imgdata = ctx.getImageData(0, 0, img.width, img.height);
  
        if (this.width === img.width && this.height === img.height) {
  
          for (let i = 0; i < imgdata.data.length; i += 4) {
            const v = imgdata.data[i + 0];
            const pos = Math.floor(i / 4);
            this.data[pos] = v;
          }
  
          this.history = [];
        }

        resolve(this.data);
      }

      img.onerror = reject;
  
      img.src = data;
    })
  }

  clearData() {
    this.data = new Array(this.width * this.height).join('0').split('').map(parseInt);
  }

  getData() {
    return this.makeGrayscale();
  }

  setLabel(options: SetLabelOptions) {
    const x = options.point.x;
    const y = options.point.y;
    const sh = options.shape;
    const sz = options.size;
    const v = options.value;
    const ov = options.paintOver;

    if (sh === 'square') {
      const xstart = x - Math.floor(sz / 2);
      const xend = xstart + sz;
      const ystart = y - Math.floor(sz / 2);
      const yend = ystart + sz;
      for (let ix = xstart; ix < xend; ix++) {
        for (let iy = ystart; iy < yend; iy++) {

          if (ix < 0 || ix >= this.width || y < 0 || y>= this.height) continue;

          const pos = (iy * this.width + ix);
          
          if (pos < 0 || pos + 1 >= this.data.length) {
            continue;
          }

          if (ov || ov === 0) {
            const cv = this.data[pos];
            if (cv === ov) {
              this.data[pos] = v;
            }
          } else {
            this.data[pos] = v;
          }
        }
      }
    }

    if (sh == 'circle') {
      const xstart = x - Math.floor(sz / 2);
      const xend = xstart + sz;
      const ystart = y - Math.floor(sz / 2);
      const yend = ystart + sz;
      for (let ix = xstart; ix < xend; ix++) {
        for (let iy = ystart; iy < yend; iy++) {

          if (ix < 0 || ix >= this.width || y < 0 || y>= this.height) continue;

          const dist = Math.sqrt((ix - x)**2 + (iy - y) ** 2);

          if (dist > (sz / 2)) {
            continue;
          }

          const pos = (iy * this.width + ix);
          
          if (pos < 0 || pos + 1 >= this.data.length) {
            continue;
          }

          if (ov || ov === 0) {
            const cv = this.data[pos];
            if (cv === ov) {
              this.data[pos] = v;
            }
          } else {
            this.data[pos] = v;
          }
        }
      }
    }
  }

  saveChanges() {
    if (this.history.length >= this.totalHist) {
      this.history.splice(0, 1)
    }
    this.history.push(([] as number[]).concat(this.data));
  }

  undo() {
    if (this.history.length > 0) this.data = this.history.pop();
  }

  hexToRgb(hex: string) {
    const bigint = parseInt(hex.substring(1, 7), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  }

  makePng(alpha=255) {
    // create off-screen canvas element
    const canvas = document.createElement('canvas');
    const ctx: any = canvas.getContext('2d');

    canvas.width = this.width;
    canvas.height = this.height;

    // create imageData object
    const idata = ctx.createImageData(this.width, this.height);

    const mbuf = new ArrayBuffer(idata.data.length);
    const mdata = new Uint32Array(mbuf);
    const buffer = new Uint8ClampedArray(mbuf);

    for (let x = 0; x < this.width; x++) {
      for (let y = 0; y < this.height; y++) {
        
        const pos = (y * this.width + x);
        let value = this.data[pos];

        value = (value < 0 ? 0 : value) & 0xff;
        
        const cm = this.colorMap[value];
        if (cm) {
          const c = this.hexToRgb(cm);
          mdata[pos] = ((value > 0 ? alpha : 0) << 24) | (c[2] << 16) | (c[1] << 8) | c[0];
        }
      }
    }

    // set our buffer as source
    idata.data.set(buffer);

    // update canvas with new data
    ctx.putImageData(idata, 0, 0);

    const dataUri = canvas.toDataURL('image/png');
    return dataUri;
  }

  makeGrayscale() {
    // create off-screen canvas element
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;

    canvas.width = this.width;
    canvas.height = this.height;

    // create imageData object
    const idata = ctx.createImageData(this.width, this.height);

    const mbuf = new ArrayBuffer(idata.data.length);
    const mdata = new Uint32Array(mbuf);
    const buffer = new Uint8ClampedArray(mbuf);

    for (let x = 0; x < this.width; x++) {
      for (let y = 0; y < this.height; y++) {
        const pos = (y * this.width + x);
        let value = this.data[pos];
        value = (value < 0 ? 0 : value) & 0xff;
        mdata[pos] = (255 << 24) | (value << 16) | (value << 8) | value;
      }
    }

    // set our buffer as source
    idata.data.set(buffer);

    // update canvas with new data
    ctx.putImageData(idata, 0, 0);

    const dataUri = canvas.toDataURL('image/png');
    return dataUri;
  }
}