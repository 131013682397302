
import { Component } from "vue-property-decorator";
import { ExtVue } from "../lib"

@Component({})
export default class Workspace extends ExtVue {
  private name = "Workspace";
  objectName = "Workspace"
  private service = "workspaces";
  loading = false;
  search = "";

  mode = "create";
  dialog = false;
  data: any = {};

  private itemData: any[] = [];
  searchFields: any[] = ['name', 'description'];

  headers: any[] = [
    {text: 'Name', value: 'name'},
    {text: 'Admin Email', value: 'admin.email'},
    {text: 'Is Active', value: 'isActive'},
    {text: 'Description', value: 'description'},
    {text: 'Actions', value: 'actions'}
  ];

  get items() {
    return this.itemData;
  }

  async load() {
    this.loading = true;
    try {
      const query: any = {$paginate: false};
      if (this.search.trim().length > 0 && this.searchFields.length > 0) {
        query.$or = this.searchFields.map((s: any) => {
          const sparam: any = {};
          sparam[s] = {$regex: this.search, $options: 'i'};
          return sparam;
        });
      }
      const items = await this.$app.service(this.service).find({query});
      this.itemData = items;
    } catch (error) {
      this.$showErrorMsg((error as any).message);
    }
    this.loading = false;
  }

  refresh() {
    this.data = {
      name: "",
      email: "",
      isActive: true,
      description: "",
    }
  }

  add() {
    this.refresh();
    this.mode = "create";
    this.dialog = true;
  }

  async editItem(item: any) {
    try {
      const itm: any = await this.$app.service(this.service).get(item._id);
      this.refresh();
      this.mode = "edit";
      this.data = Object.assign({}, this.data, itm, {email: itm.admin?.email});
      this.dialog = true;
    } catch (error) {
      this.$showErrorMsg((error as any).message);
    }
  }

  async deleteItem(item: any) {
    if (await this.$confirm("Remove selected Item ?")) {
      try {
        await this.$app.service(this.service).remove(item._id);
        this.$showSuccessMsg(`${this.objectName} successfully removed!`);
        this.load();
      } catch (error) {
        this.$showErrorMsg((error as any).message);
      }
    }
  }

  async save() {
    const isValid = this.$refs.mainForm.validate();
    if (isValid) {
      if (await this.$confirm('Save Data ?')) {
        try {
          if (this.mode == "edit") {
            await this.$app.service(this.service).patch(this.data._id, this.data);
          } else {
            await this.$app.service(this.service).create(this.data);
          }
          this.load();
          this.refresh();
          this.$showSuccessMsg(`${this.objectName} successfully ${this.mode == 'create' ? 'created!': 'updated!'}`);
          if (this.mode == "edit") this.dialog = false;
        } catch (error) {
          this.$showErrorMsg((error as any).message);
        }
      }
    }
  }


  mounted() {
    setTimeout(() => {
      this.load();
    }, 1000)
  }
}
